.locations {
  &.ant-table-wrapper {
    tbody > .ant-table-row-level-0 > td {
      @apply border-b-gray-chateau;
    }

    tbody > .ant-table-row-level-1.ant-table-row:hover > td, tbody > .ant-table-row-level-1 > td.ant-table-cell-row-hover {
      @apply bg-gray97;
    }

    tbody > .ant-table-row-level-1 > td {
      @apply border-b-light-grey;
    }

    tbody > .ant-table-row-level-1.ant-table-row:hover > td, tbody > .ant-table-row-level-1 > td.ant-table-cell-row-hover {
      @apply bg-white;
    }

    tr {
      &.ant-table-row-level-1,
      &.ant-table-row-level-2 {
        @apply bg-gray97;

        .ant-table-cell-fix-left,
        .ant-table-cell-fix-right {
          @apply bg-gray97;
        }
      }

      &.ant-table-row-level-1 {
        @apply cursor-pointer;

        td {
          @apply font-light;

          &.location {
            @apply text-black/40;
          }
        }
      }
    }
  }
}

