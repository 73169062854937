.ant-menu-item .ant-menu-item-icon + span {
  @apply ml-0;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  @apply border-b-0 transition-none;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  @apply p-1;
}

.ant-menu-item .ant-menu-item-icon {
  @apply transition-background duration-300;
}

.ant-menu-item .ant-avatar:hover {
  @apply bg-alizarin;
}

.ant-breadcrumb .ant-breadcrumb-link span.anticon {
  @apply inline-flex;
}
