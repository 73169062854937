@use 'typography';
@use 'tailwind';

body {
  margin: 0;

  p {
    margin-bottom: 0;
  }
}
