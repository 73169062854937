@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .descriptions-vertical {
    &.ant-descriptions {
      .ant-descriptions-row {
        @apply flex flex-col;

        > td {
          @apply pb-1;

          .ant-descriptions-item-label::after {
            top: unset;
            margin-left: 0;
          }
        }
      }

      .ant-descriptions-item-container .ant-descriptions-item-label {
        @apply grow shrink-0 basis-24 max-w-[200px] font-medium;
      }

      &.max-w-120px {
        .ant-descriptions-item-container .ant-descriptions-item-label {
          @apply max-w-[120px] font-medium;
        }
      }

      .ant-descriptions-item-container .ant-descriptions-item-content {
        @apply max-w-[75%] font-light flex flex-col;
      }
    }
  }

  .ant-form-fix-item-label-colon {
    &.ant-form {
      .ant-form-item-label > label:not(.ant-form-item-no-colon)::after {
          top: unset;
          margin-left: 0;
      }
    }
  }
}

@layer utilities {
  .center-v-h {
    @apply items-center justify-center;
  }
}

.ant-btn-primary {
  @apply bg-alizarin;
}
