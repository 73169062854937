.radioItem {
  @apply w-full;

  &__dateRadio {
    @apply w-full;

    > span {
      &:last-child {
        @apply w-full pr-0;

        > div {
          @apply w-full;
        }
      }
    }
  }
}
